import React from "react"
import Header from "../components/header"
import Layout from "../components/layout"
import Container from "../components/container"

export default () => (
  <Layout>
    <Container> 
      <Header headerText="About Me" />
      <h2>I'm Steve.</h2>
      <p>
        By day I work at <a href="https://spotify.com">Spotify UK</a> configuring campaigns and web pages.
      </p>
      <p>
        By night I code, make games, tinker, play, shoot videos and generally do all manner of geekery.
      </p>
      <p>
        I've been building websites since the early 2000s - back when a few hacked together PHP scripts could get you something that 90% of people didn't think was possible.
      </p>
      <p>
        My first successful project was <a href="https://gaminghours.com">GamingHours</a> back in 2004 - a video game review site that was around before WordPress allowed every man and his dog to have a gaming site.
      </p>
      <p>
        Damn I wish I'd have kept with it.
      </p>
      <p>
        Since then, I've done a lot of different things.
      </p>
      <p>
        I'm going to keep this site mainly about the tech and video side of things. 
      </p>
    </Container>
  </Layout>
)